import React from 'react';
import { get } from 'lodash';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { getFieldData, getLiveUrl } from 'common/utils';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleFive({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const headshotSection =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');
  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');

  return (
    <>
      <table
        style={{
          margin: '0 !important',
          padding: '0 !important',
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
                paddingBottom: '2px',
              },
            })}
          </tr>
        )}
        <tr>
          <td
            style={{
              fontSize: 'inherit',
              paddingBottom: '8px',
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              role="presentation"
              style={{}}
            >
              <tr>
                <table
                  cellPadding="0px"
                  cellSpacing="0"
                  border="0"
                  ref={mainContentRef}
                >
                  <tr>
                    {(getFieldData(fields, 'headshotUrl') ||
                      getFieldData(fields, 'companyLogo')) && (
                      <td
                        style={{
                          paddingRight: '14px',
                        }}
                      >
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                          style={{
                            fontSize: 'inherit',
                          }}
                        >
                          {getFieldData(fields, 'headshotUrl') && (
                            <tr align="center">
                              {HeadshotImage({
                                data: getFieldData(fields, 'headshotUrl'),
                                design,
                                style: {
                                  paddingBottom: '8px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyLogo') && (
                            <tr align="center">
                              {CompanyLogo({
                                data: getFieldData(fields, 'companyLogo'),
                                design,
                                style: {
                                  paddingBottom: '8px',
                                },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    )}
                    {!allUrlsAreNull && (
                      <td
                        className={TEXT_IDS.SEPARATOR}
                        style={{
                          paddingLeft: !headshotSection ? '14px' : '0px',
                          borderLeft: !headshotSection
                            ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                            : 'none',
                        }}
                      >
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                          style={{
                            fontSize: 'inherit',
                          }}
                        >
                          <tr>
                            <td>
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                style={{
                                  fontSize: 'inherit',
                                  paddingBottom: '6px',
                                }}
                              >
                                {getFieldData(fields, 'name') && (
                                  <tr>
                                    {Name({
                                      signature,
                                      data: getFieldData(fields, 'name'),
                                      style: {
                                        fontWeight: 700,
                                        color: design?.primaryBrandColor,
                                        paddingBottom: '2px',
                                      },
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'position') && (
                                  <tr>
                                    {Position({
                                      signature,
                                      data: getFieldData(fields, 'position'),
                                      paddingBottom: '2px',
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'department') && (
                                  <tr>
                                    {Department({
                                      signature,
                                      data: getFieldData(fields, 'department'),
                                      paddingBottom: '2px',
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'companyName') && (
                                  <tr>
                                    {CompanyName({
                                      signature,
                                      data: getFieldData(fields, 'companyName'),
                                      paddingBottom: '2px',
                                    })}
                                  </tr>
                                )}
                              </table>
                            </td>
                          </tr>
                          {!!socialFields?.length && (
                            <tr>
                              <Divider signature={signature} design={design} />
                            </tr>
                          )}
                          {!!socialFields?.length && (
                            <tr>
                              <td style={{ paddingBottom: '2px' }}>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                >
                                  {!!socialFields?.length && (
                                    <tr>
                                      {SocialLinks({
                                        signature,
                                        showAwsIcons,
                                        data: socialFields,
                                        design,
                                      })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}
                          {getFieldData(fields, 'phone') && (
                            <tr>
                              {Phone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'phone'),
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyPhone') && (
                            <tr>
                              {CompanyPhone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyPhone'),
                                style: { paddingRight: '8px' },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'email') && (
                            <tr>
                              {Email({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'email'),
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyEmail') && (
                            <tr>
                              {CompanyEmail({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyEmail'),
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'website') && (
                            <tr>
                              {Website({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'website'),
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyAddress') && (
                            <tr>
                              {CompanyAddress({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyAddress'),
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    )}
                  </tr>
                </table>
              </tr>
              {/* {getFieldData(fields, 'ctaUrl') &&
      getFieldData(fields, 'ctaTitle') && (
        <tr>
          {CallToAction({
            signature,
            showAwsIcons,
            design,
            data: {
              ctaUrl: getFieldData(fields, 'ctaUrl'),
              ctaTitle: getFieldData(fields, 'ctaTitle'),
            },
          })}
        </tr>
      )}

    {getFieldData(fields, 'ctaBannerImg') &&
      getFieldData(fields, 'ctaBannerUrl') && (
        <tr>
          {CallToActionBanner({
            data: {
              ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
              ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
            },
            design,
            style: {
              textAlign: 'left',
              paddingBottom: '8px',
            },
          })}
        </tr>
      )}
    {!!getFieldData(fields, 'socialBanners')?.value?.length && (
      <tr>
        {SocialBanners({
          data: getFieldData(fields, 'socialBanners'),
          design,
        })}
      </tr>
    )}
    {getFieldData(fields, 'footer') && (
      <tr>
        {Footer({
          data: getFieldData(fields, 'footer'),
          design,
        })}
      </tr>
    )} */}
            </table>
          </td>
        </tr>

        {/* <Branding hideBranding={hideBranding} /> */}
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleFive;
