import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import React from 'react';

const OnboardingSignaturePreview = ({ signature }) => {
  const FinalTemplate = dynamicTemplate({
    templateKey: signature?.template?.key,
  });

  return (
    <div className="pt-4 px-4">
      {FinalTemplate && (
        <div className="w-full min-w-[400px] max-w-full mx-auto bg-white-0 rounded-lg shadow-md overflow-hidden">
          <div className="border border-solid border-gray-200 rounded-lg p-6 text">
            <div>
              <FinalTemplate signature={signature} showAwsIcons />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingSignaturePreview;
