/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleThirty({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const allUrlsAreNull = socialFields?.every((obj) => obj?.value === '');

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');
  return (
    <>
      <table
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
              }}
            >
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    ref={mainContentRef}
                  >
                    <tr>
                      {/* {!!socialFields?.length && (
            <td
              style={{
                verticalAlign: 'center',
              }}
            >
              <table>

                  <tr>
                    {SocialLinks({
                      signature,
                      showAwsIcons,
                      alignment: 'vertical',
                      data: socialFields,
                      design,
                      style: {
                        paddingRight: '14px',
                      },
                    })}
                  </tr>

              </table>
            </td>
          )} */}
                      {!section1 && (
                        <td
                          style={{
                            paddingRight: '14px',
                          }}
                        >
                          <table cellPadding="0" cellSpacing="0">
                            <tr align="center">
                              {getFieldData(fields, 'headshotUrl') &&
                                HeadshotImage({
                                  data: getFieldData(fields, 'headshotUrl'),
                                  design,
                                })}
                            </tr>
                            <tr align="center">
                              {getFieldData(fields, 'companyLogo') &&
                                CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                })}
                            </tr>
                          </table>
                        </td>
                      )}
                      <td>
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          style={{
                            borderCollapse: 'collapse',
                          }}
                        >
                          {(getFieldData(fields, 'name') ||
                            getFieldData(fields, 'position') ||
                            getFieldData(fields, 'department') ||
                            getFieldData(fields, 'companyName')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    paddingBottom: '8px',
                                  }}
                                >
                                  {getFieldData(fields, 'name') && (
                                    <tr>
                                      {Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontWeight: 700,
                                          color: design?.primaryBrandColor,
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'position') && (
                                    <tr>
                                      {Position({
                                        signature,
                                        data: getFieldData(fields, 'position'),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                    </tr>
                                  )}

                                  {(getFieldData(fields, 'department') ||
                                    getFieldData(fields, 'companyName')) && (
                                    <tr>
                                      <td>
                                        <table
                                          cellPadding="0"
                                          cellSpacing="0"
                                          border="0"
                                          style={{
                                            borderCollapse:
                                              'collapse !important',
                                            fontSize: 'inherit',
                                          }}
                                        >
                                          <tr>
                                            {getFieldData(
                                              fields,
                                              'department',
                                            ) &&
                                              Department({
                                                signature,
                                                data: getFieldData(
                                                  fields,
                                                  'department',
                                                ),
                                                style: {
                                                  fontFamily: 'inherit',
                                                  paddingRight: '8px',
                                                },
                                              })}

                                            {getFieldData(
                                              fields,
                                              'companyName',
                                            ) &&
                                              CompanyName({
                                                signature,
                                                data: getFieldData(
                                                  fields,
                                                  'companyName',
                                                ),
                                                style: {
                                                  fontFamily: 'inherit',
                                                  fontStyle: 'italic',
                                                },
                                              })}
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  )}

                                  <tr>
                                    {!!socialFields?.length &&
                                      SocialLinks({
                                        signature,
                                        showAwsIcons,
                                        data: socialFields,
                                        design,
                                      })}
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          )}

                          {(getFieldData(fields, 'phone') ||
                            getFieldData(fields, 'email') ||
                            getFieldData(fields, 'website') ||
                            getFieldData(fields, 'companyAddress')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                >
                                  {getFieldData(fields, 'phone') && (
                                    <tr>
                                      {Phone({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'phone'),
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'companyPhone') && (
                                    <tr>
                                      {CompanyPhone({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyPhone',
                                        ),
                                        style: { paddingRight: '8px' },
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'email') && (
                                    <tr>
                                      {Email({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'email'),
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'companyEmail') && (
                                    <tr>
                                      {CompanyEmail({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyEmail',
                                        ),
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'website') && (
                                    <tr>
                                      {Website({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'website'),
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'companyAddress') && (
                                    <tr>
                                      {CompanyAddress({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyAddress',
                                        ),
                                      })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* {getFieldData(fields, 'ctaUrl') &&
          getFieldData(fields, 'ctaTitle') && (
            <tr>
              {CallToAction({
                signature,
                showAwsIcons,
                design,
                data: {
                  ctaUrl: getFieldData(fields, 'ctaUrl'),
                  ctaTitle: getFieldData(fields, 'ctaTitle'),
                },
                style: {
                  paddingBottom: '8px',
                },
              })}
            </tr>
          )}

        {getFieldData(fields, 'ctaBannerImg') &&
          getFieldData(fields, 'ctaBannerUrl') && (
            <tr>
              {CallToActionBanner({
                data: {
                  ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                  ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                },
                design,
                style: { textAlign: 'left' },
              })}
            </tr>
          )}
        {!!getFieldData(fields, 'socialBanners')?.value?.length && (
          <tr>
            {SocialBanners({
              data: getFieldData(fields, 'socialBanners'),
              design,
            })}
          </tr>
        )}
        {getFieldData(fields, 'footer') && (
          <tr>
            {Footer({
              data: getFieldData(fields, 'footer'),
              design,
            })}
          </tr>
        )} */}
            </table>
          </td>
        </tr>
        {/* <tr>
      <Branding hideBranding={hideBranding} />
    </tr> */}
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleThirty;
