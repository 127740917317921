/* eslint-disable no-nested-ternary */
import { useAppContext } from 'AppContext';
import { Slider } from 'components/ui/slider';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Label } from 'components/ui/label';
import { SIGN_OFF_OPTIONS } from 'common/constants';
import { get, isString } from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { ClearableInput } from 'components/Inputs';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { getLiveUrlWithoutTimeStamp, URL_REGEX } from 'common/utils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import ColorPicker from 'components/ColorPicker';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { cn, isValidUrl } from 'lib/utils';
import { Checkbox } from 'components/ui/checkbox';
import CtaBannerDialog from './components/CtaBannerDialog';

export const SOCIAL_BANNER_LINKS = [
  {
    platform: 'Facebook',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'facebook-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'facebook-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'facebook-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Instagram',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'instagram-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'instagram-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'instagram-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Twitter',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'twitter-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'twitter-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'twitter-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Pinterest',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'pinterest-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'pinterest-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'pinterest-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'LinkedIn',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'linkedin-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'linkedin-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'linkedin-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'YouTube',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'youtube-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'youtube-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'youtube-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Dribbble',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'dribbble-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'dribbble-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'dribbble-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Behance',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'behance-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'behance-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'behance-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Patreon',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'patreon-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'patreon-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'patreon-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Vimeo',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'vimeo-social-banner-icon-v1.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'vimeo-social-banner-icon-v2.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'vimeo-social-banner-icon-v3.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Amazon',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'amazon-social-banner-icon-black.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'amazon-social-banner-icon-white.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'amazon-social-banner-icon-gray.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'App Store',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'appstore-social-banner-icon-black.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'appstore-social-banner-icon-white.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'appstore-social-banner-icon-gray.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Ebay',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'ebay-social-banner-icon-black.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'ebay-social-banner-icon-white.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'ebay-social-banner-icon-gray.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Google My Business',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'googlemy-social-banner-icon-black.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'googlemy-social-banner-icon-white.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'googlemy-social-banner-icon-gray.png?variant=3',
        ),
      },
    ],
    link: '',
  },
  {
    platform: 'Google Play',
    imgUrls: [
      {
        variant: 1,
        url: getLiveUrlWithoutTimeStamp(
          'googleplay-social-banner-icon-black.png?variant=1',
        ),
      },
      {
        variant: 2,
        url: getLiveUrlWithoutTimeStamp(
          'googleplay-social-banner-icon-white.png?variant=2',
        ),
      },
      {
        variant: 3,
        url: getLiveUrlWithoutTimeStamp(
          'googleplay-social-banner-icon-gray.png?variant=3',
        ),
      },
    ],
    link: '',
  },
];

function CallToActionControl() {
  const {
    state: { signature },
    setSignature,
    formRef,
  } = useAppContext();
  const [showCustomSignOffInput, setShowCustomSignOffInput] = useState(false);
  const [showLogoUrlInput, setShowLogoUrlInput] = useState(false);

  const previousValuesRef = useRef({});
  const ctaBannerDialogRef = useRef();

  const { styles } = signature?.design || {};
  const { cta, ctaBanner, signOff, socialBanners } = styles || {
    cta: {
      style: {
        width: 0,
        borderRadius: 4,
        type: 'colored',
      },
    },
    ctaBanner: {
      style: {
        width: 250,
      },
    },
    socialBanners: {
      style: {
        width: 100,
      },
    },
    signOff: {
      style: {
        fontSize: 25,
        color: '#000000',
      },
    },
  };

  const urlValidation = z.string().refine(
    (val) => {
      const urlRegex = URL_REGEX;
      return urlRegex.test(val);
    },
    {
      message: 'URL must start with https://  .',
    },
  );

  const ctaTitle = signature?.fields?.filter((field) =>
    ['ctaTitle'].includes(field.name),
  );
  const ctaUrl = signature?.fields?.filter((field) =>
    ['ctaUrl'].includes(field.name),
  );
  const ctaBannerImgFields = signature?.fields?.filter((field) =>
    ['ctaBannerImg'].includes(field.name),
  );

  const ctaBannerUrlFields = signature?.fields?.filter((field) =>
    ['ctaBannerUrl'].includes(field.name),
  );

  const signOffFields = signature?.fields?.filter((field) =>
    ['signOff'].includes(field.name),
  );
  const socialBannersFields = signature?.fields?.filter((field) =>
    ['socialBanners'].includes(field.name),
  );
  const ctaTitleSchema = ctaTitle?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const ctaUrlSchema = ctaUrl?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? urlValidation.optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const ctaBannerImageSchema = ctaBannerImgFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const ctaBannerUrlSchema = ctaBannerUrlFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? urlValidation.optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const signOffFieldsSchema = signOffFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const socialBannersSchema = socialBannersFields?.reduce((acc, field) => {
    if (field.name === 'socialBanners') {
      acc[field.name] = z
        .array(
          z.object({
            platform: z.string(), // Required platform field
            link:
              field.value !== null
                ? urlValidation.optional().or(z.literal(''))
                : z.string().nullable(),
            imgUrl: z.string().optional().or(z.literal('')), // Optional imgUrl field
          }),
        )
        .optional();
    }
    return acc;
  }, {});

  const schema = z.object({
    ...(ctaTitleSchema ?? {}),
    ...(ctaUrlSchema ?? {}),
    ...(ctaBannerImageSchema ?? {}),
    ...(ctaBannerUrlSchema ?? {}),
    ...(signOffFieldsSchema ?? {}),
    ...(socialBannersSchema ?? {}),
  });

  const defaultValues = [
    ...ctaTitle,
    ...ctaUrl,
    ...ctaBannerImgFields,
    ...ctaBannerUrlFields,
    ...signOffFields,
    ...socialBannersFields,
  ]?.reduce((acc, field) => {
    acc[field.name] = field.value || '';
    return acc;
  }, {});

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      ...defaultValues,
    },
  });

  useImperativeHandle(formRef, () => ({
    form,
  }));

  const watch = form.watch();

  const handleFormChange = () => {
    const { ...values } = form.getValues();
    const updatedFields = [...(signature?.fields ?? [])].map((field) => ({
      ...field,
      value: values[field.name] ?? field?.value,
    }));

    const updatedValues = {
      ...signature,
      fields: updatedFields,
    };

    if (
      JSON.stringify(previousValuesRef.current) !==
      JSON.stringify(updatedValues)
    ) {
      setSignature(updatedValues);
      previousValuesRef.current = updatedValues;
    }
    setSignature(updatedValues);
  };

  useEffect(() => {
    const subscription = form.watch(handleFormChange);
    return () => subscription.unsubscribe();
  }, [form, signature]);

  const handleDesignStyleChange = (key, values) => {
    const design = get(signature, 'design', {});
    const designStyles = get(design, 'styles', {});
    const data = {
      ...signature,
      design: {
        ...signature?.design,
        styles: {
          ...designStyles,
          [key]: {
            ...values,
          },
        },
      },
    };
    setSignature(data);
  };
  const width = isString(cta?.style?.width) ? 0 : cta?.style?.width || 50;
  const ctaBannerWidth = isString(ctaBanner?.style?.width)
    ? 0
    : ctaBanner?.style?.width || 250;
  const socialBannersSize = isString(socialBanners?.style?.width)
    ? 0
    : socialBanners?.style?.width || 120;
  const signOffFontSize = isString(signOff?.style?.fontSize)
    ? 0
    : signOff?.style?.fontSize || 25;

  const signOffColor =
    signOff?.style?.color || signature?.design?.primaryBrandColor;

  const ctabannerborderRadius = ctaBanner?.style?.ctaborderRadius || 0;
  const borderRadius = cta?.style?.borderRadius || 0;
  const ctabuttontype = cta?.style?.type || 'colored';

  const handleSocialBannerChange = (platform, value, imgUrls) => {
    const socialBanners = form.getValues('socialBanners') || [];
    const index = socialBanners.findIndex(
      (banner) => banner.platform === platform,
    );

    const imgUrl = socialBanners?.[index]?.imgUrl ?? imgUrls[0]?.url;

    if (value) {
      if (index !== -1) {
        socialBanners[index].link = value;
      } else {
        socialBanners.push({ platform, link: value, imgUrl });
      }
    } else if (index !== -1) {
      socialBanners.splice(index, 1);
    }

    form.setValue('socialBanners', socialBanners);
  };

  const handleImageVariantChange = (platform, selectedUrl) => {
    const socialBanners = form.getValues('socialBanners') || [];
    const index = socialBanners.findIndex(
      (banner) => banner.platform === platform,
    );
    const existingBanner = socialBanners?.[index] ?? null;
    const bannerLink = existingBanner ? existingBanner.link : '';
    if (!bannerLink) return;
    if (index !== -1) {
      socialBanners[index].imgUrl = selectedUrl;
    } else {
      socialBanners.push({ platform, imgUrl: selectedUrl });
    }

    form.setValue('socialBanners', socialBanners);
  };

  const isCustomText =
    watch?.signOff &&
    !SIGN_OFF_OPTIONS?.find((op) => op.value === watch?.signOff);

  const accordionData = [
    {
      value: 'item-1',
      title: 'Call to action Button',
      content: (
        <div>
          <div>
            {ctaTitle?.map((field) => (
              <FormField
                key={field.name}
                control={form.control}
                name={field.name}
                render={({ field: formField }) => (
                  <FormItem>
                    <FormControl>
                      <ClearableInput
                        className="max-w-full mb-2"
                        placeholder={field.label}
                        {...formField}
                        type={field.type}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}
            {ctaUrl?.map((field) => (
              <FormField
                key={field.name}
                control={form.control}
                name={field.name}
                render={({ field: formField }) => (
                  <FormItem>
                    <FormControl>
                      <ClearableInput
                        className="max-w-full mb-2"
                        placeholder={field.label}
                        {...formField}
                        type={field.type}
                        allowClear
                        onChange={(e) => {
                          formField.onChange(e);
                          form.trigger(field.name);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}
          </div>
          <div>
            {watch?.ctaUrl && (
              <div className="rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>CTA Button Style</span>
                  </div>
                  <RadioGroup
                    value={ctabuttontype}
                    onValueChange={(value) =>
                      handleDesignStyleChange('cta', {
                        style: {
                          ...cta?.style,
                          type: value,
                        },
                      })
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="colored"
                        id="colored-ctaButtonStyle"
                      />
                      <Label
                        htmlFor="colored-ctaButtonStyle"
                        className="px-4 py-1 rounded-full bg-primary text-white-0"
                      >
                        Colored
                      </Label>
                      <RadioGroupItem
                        value="bordered"
                        id="bordered-ctaButtonStyle"
                      />
                      <Label
                        htmlFor="bordered-ctaButtonStyle"
                        className="px-4 py-1 rounded-full bg-transparent border-1 border-solid border-primary"
                      >
                        Bordered
                      </Label>
                      <RadioGroupItem value="text" id="text-ctaButtonStyle" />
                      <Label
                        htmlFor="text-ctaButtonStyle"
                        className="px-4 py-1 rounded-full"
                      >
                        Text
                      </Label>
                    </div>
                  </RadioGroup>
                </div>
              </div>
            )}
          </div>
          <div>
            {watch?.ctaUrl && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Width</span>
                    <span>{width}px</span>
                  </div>
                  <Slider
                    min={50}
                    max={300}
                    value={[width]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('cta', {
                        style: {
                          ...cta?.style,
                          width: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {watch?.ctaUrl && ctabuttontype !== 'text' && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Border Radius</span>
                    <span>{borderRadius}px</span>
                  </div>
                  <Slider
                    min={0}
                    max={15}
                    value={[borderRadius]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('cta', {
                        style: {
                          ...cta?.style,
                          borderRadius: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      value: 'item-2',
      title: 'Call to action Banner',
      content: (
        <>
          <CtaBannerDialog
            ref={ctaBannerDialogRef}
            signature={signature}
            ctaBannerImgFields={ctaBannerImgFields}
            form={form}
            watch={watch}
          />

          <div>
            {watch.ctaBannerImg ? (
              <ImageUploadPreview
                className="rounded-lg mb-4"
                previewImageClassName="max-w-[80%]"
                onChange={() => {
                  form.setValue('ctaBannerImg', '');
                }}
                imageUrl={watch.ctaBannerImg}
              />
            ) : (
              <div
                className="relative rounded-lg h-[100px] overflow-hidden text-center flex items-center justify-center text-[16px] border-[1px] border-dashed border-gray-0 hover:border-primary hover:bg-transparent bg-transparent cursor-pointer"
                onClick={() => ctaBannerDialogRef.current?.open()}
              >
                Select or upload your CTA Banner
              </div>
            )}
            <div className="flex items-center mt-4">
              <Checkbox
                checked={showLogoUrlInput}
                onCheckedChange={setShowLogoUrlInput}
                className="mb-2 data-[state=unchecked]:bg-white-0 data-[state=unchecked]:text-primary-foreground"
              />
              <span className="ml-2">Host your image</span>
            </div>
            {showLogoUrlInput && (
              <div className="mt-2">
                <FormField
                  name="ctaBannerImg"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <ClearableInput
                          className="max-w-full mb-2"
                          placeholder="Enter hosted image url"
                          {...field}
                          type="text"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          allowClear
                          onChange={(e) => {
                            const newUrl = e.target.value;
                            field.onChange(newUrl);
                            form.trigger('ctaBannerImg');
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            <div>
              {watch?.ctaBannerImg &&
                ctaBannerUrlFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem>
                        <FormControl>
                          <ClearableInput
                            className="max-w-full mb-2 mt-2"
                            placeholder={field.label}
                            {...formField}
                            type={field.type}
                            allowClear
                            onChange={(e) => {
                              formField.onChange(e);
                              form.trigger(field.name);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
            </div>

            <div>
              {watch?.ctaBannerUrl && watch?.ctaBannerImg && (
                <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                  <div className="m-0">
                    <div className="mb-2 flex justify-between text-medium-base font-primary">
                      <span>Width</span>
                      <span>{ctaBannerWidth}px</span>
                    </div>
                    <Slider
                      min={50}
                      max={600}
                      value={[ctaBannerWidth]}
                      onValueChange={([value]) =>
                        handleDesignStyleChange('ctaBanner', {
                          style: {
                            ...ctaBanner?.style,
                            width: value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            {watch?.ctaBannerUrl && watch?.ctaBannerImg && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Border Radius</span>
                    <span>{ctabannerborderRadius}px</span>
                  </div>
                  <Slider
                    min={0}
                    max={15}
                    value={[ctabannerborderRadius]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('ctaBanner', {
                        style: {
                          ...ctaBanner?.style,
                          ctaborderRadius: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      value: 'item-3',
      title: 'Social Banner',
      content: (
        <div>
          {/* <div>
                <h2>Social Banner</h2>
              </div> */}
          {SOCIAL_BANNER_LINKS.map(({ platform, imgUrls, link }) => {
            const socialBanners = form.getValues('socialBanners') || [];
            const existingBanner = socialBanners.find(
              (banner) => banner.platform === platform,
            );
            const bannerLink = existingBanner ? existingBanner.link : link;
            const selectedImage = existingBanner?.imgUrl || imgUrls[0].url;
            const showError = bannerLink && !isValidUrl(bannerLink);

            return (
              <div key={platform} className="mb-2">
                <div className="grid grid-cols-[auto_1fr] gap-2">
                  {/* Dropdown to select image variant */}
                  <Select
                    value={selectedImage}
                    onValueChange={(val) =>
                      handleImageVariantChange(platform, val)
                    }
                    className="w-[auto] p-0"
                  >
                    <SelectTrigger
                      className="w-[auto] h-[auto] cursor-pointer bg-transparent border-0 p-0"
                      hideDropdownArrow
                    >
                      <SelectValue placeholder="Select Image" />
                    </SelectTrigger>
                    <SelectContent>
                      {imgUrls.map((img) => (
                        <SelectItem value={img.url} key={img.variant}>
                          <img
                            src={img.url}
                            alt={`${platform} variant ${img.variant}`}
                            className="h-8 w-auto"
                          />
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <div>
                    <ClearableInput
                      placeholder={`Enter link for ${platform}`}
                      value={bannerLink}
                      className="w-full"
                      onChange={(e) =>
                        handleSocialBannerChange(
                          platform,
                          e.target.value,
                          imgUrls,
                        )
                      }
                      type="text"
                    />
                    {showError && (
                      <FormMessage className="m-0">
                        URL must start with https://{' '}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {form.getValues('socialBanners')?.some((item) => item.link) && (
            <div>
              <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Width</span>
                    <span>{socialBannersSize}px</span>
                  </div>
                  <Slider
                    min={50}
                    max={250}
                    value={[socialBannersSize]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('socialBanners', {
                        style: {
                          ...socialBanners?.style,
                          width: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      value: 'item-4',
      title: 'Sign Off',
      content: (
        <div className="mb-4 mt-6 w-full">
          {/* <div>
                <h2>Sign Off</h2>
              </div> */}
          <div
            className={cn(
              'grid gap-4',
              isCustomText || watch?.signOff
                ? ' grid-cols-[1fr_auto] '
                : 'grid-cols-[1fr]',
            )}
          >
            <FormField
              name="signOff"
              control={form.control}
              render={({ field }) => (
                <>
                  <div>
                    <FormItem className="mb-0">
                      <FormControl>
                        <Select
                          onOpenChange={(open) => {
                            if (open) {
                              setShowCustomSignOffInput(false);
                            }
                          }}
                          value={
                            isCustomText || showCustomSignOffInput
                              ? 'Custom Text'
                              : field.value
                          }
                          {...(!showCustomSignOffInput
                            ? {
                                onValueChange: (val) => {
                                  // Set value to empty string if "None" is selected
                                  if (val === 'Custom Text') {
                                    setShowCustomSignOffInput(true);
                                    form.setValue('signOff', '');
                                    return;
                                  }
                                  if (val === null) {
                                    field.onChange('');
                                  } else {
                                    field.onChange(val);
                                  }
                                },
                              }
                            : {})}
                          className="bg-white-0 w-full"
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select Sign Off" />
                          </SelectTrigger>
                          <SelectContent side="top">
                            {SIGN_OFF_OPTIONS?.map((item) => (
                              <SelectItem
                                value={item.value}
                                className="mb-0 w-full"
                                key={item.label}
                              >
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                </>
              )}
            />

            {watch?.signOff && (
              <ColorPicker
                defaultValue={signOffColor}
                value={signOffColor}
                colorPickerWrapperClassName="justify-end mb-2"
                onChange={(value) => {
                  handleDesignStyleChange('signOff', {
                    style: {
                      ...signOff?.style,
                      color: `#${value.toHex().slice(0, 6)}`,
                    },
                  });
                }}
              />
            )}
          </div>

          <div>
            {(showCustomSignOffInput || isCustomText) && (
              <div className="mt-4">
                <ClearableInput
                  placeholder="Enter custom sign off"
                  value={watch?.signOff}
                  onChange={(e) => form.setValue('signOff', e.target.value)} // Set custom text value
                />
              </div>
            )}
          </div>

          <div>
            {watch?.signOff && (
              <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                <div className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Size</span>
                    <span>{signOffFontSize}px</span>
                  </div>
                  <Slider
                    min={12}
                    max={40}
                    value={[signOffFontSize]}
                    onValueChange={([value]) =>
                      handleDesignStyleChange('signOff', {
                        style: {
                          ...signOff?.style,
                          fontSize: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <header className=" px-[16px] h-[55px] flex items-center">
        <h1 className="font-primary text-h6 font-bold">Call To Action</h1>
      </header>
      <div className="px-[16px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(() => {})} className="w-full">
            <Accordion type="single" collapsible defaultValue="item-1">
              {accordionData.map((item) => (
                <AccordionItem key={item.value} value={item.value}>
                  <AccordionTrigger className="bg-[transparent] border-1 border-solid shadow-none border-gray-0 rounded-[8px] hover:no-underline cursor-pointer outline-none focus-visible:outline-none">
                    <h3 className="font-primary text-[16px] font-bold text-neutral-1000 m-0">
                      {item.title}
                    </h3>
                  </AccordionTrigger>
                  <AccordionContent className="p-0">
                    {item.content}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </form>
        </Form>
      </div>
    </div>
  );
}

export default CallToActionControl;
