import { get } from 'lodash';
import { getFieldData, getLiveUrl } from 'common/utils';
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import CompanyAddress from './components/CompanyAddress';
import Website from './components/Website';
import CompanyLogo from './components/CompanyLogo';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleSix({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const ctadivider =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl') &&
    !getFieldData(fields, 'footer');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');

  return (
    <>
      <table
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          margin: '0 !important',
          padding: '0 !important',
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontWeight: 700,
                fontFamily: 'inherit',
                paddingBottom: '2px',
              },
            })}
          </tr>
        )}
        <tr>
          <td
            aria-label="td"
            style={{
              paddingBottom: '2px',
            }}
          >
            <table width="max-content" ref={mainContentRef}>
              <tr>
                <td>
                  <table
                    width="100%"
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    role="presentation"
                    style={{
                      fontSize: 'inherit',
                      // borderBottom: !allUrlsAreNull
                      //   ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                      //   : '',
                      paddingBottom: '6px',
                    }}
                  >
                    <tr>
                      <td
                        valign="bottom"
                        style={{
                          fontStyle: 'normal',
                        }}
                      >
                        <table cellPadding="0" cellSpacing="0" border="0">
                          {getFieldData(fields, 'name') && (
                            <tr>
                              {Name({
                                signature,
                                data: getFieldData(fields, 'name'),
                                style: {
                                  paddingBottom: '2px',
                                },
                              })}
                            </tr>
                          )}

                          {getFieldData(fields, 'position') && (
                            <tr>
                              {Position({
                                signature,
                                data: getFieldData(fields, 'position'),
                                style: {
                                  paddingBottom: '2px',
                                },
                              })}
                            </tr>
                          )}
                          {(getFieldData(fields, 'department') ||
                            getFieldData(fields, 'companyName')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0px"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    paddingBottom: '2px',
                                  }}
                                >
                                  <tr>
                                    {getFieldData(fields, 'department') &&
                                      Department({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'department',
                                        ),
                                        style: {
                                          paddingRight: '8px',
                                        },
                                      })}
                                    {getFieldData(fields, 'companyName') &&
                                      CompanyName({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'companyName',
                                        ),
                                      })}
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          )}
                          {!!socialFields?.length &&
                            SocialLinks({
                              signature,
                              showAwsIcons,
                              data: socialFields,
                              design,
                              tdProps: {
                                align: 'left',
                              },
                              style: { paddingBottom: '2px' },
                            })}
                        </table>
                      </td>
                      {getFieldData(fields, 'headshotUrl') &&
                        HeadshotImage({
                          data: getFieldData(fields, 'headshotUrl'),
                          design,
                          align: 'right',
                        })}
                    </tr>
                  </table>
                </td>
              </tr>
              {!allUrlsAreNull && (
                <tr>
                  <Divider signature={signature} design={design} />
                </tr>
              )}
              {!allUrlsAreNull && (
                <tr>
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      role="presentation"
                      width="100%"
                      style={{
                        fontSize: 'inherit',
                      }}
                    >
                      {(getFieldData(fields, 'phone') ||
                        getFieldData(fields, 'email')) && (
                        <tr>
                          {getFieldData(fields, 'phone') &&
                            Phone({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'phone'),
                              style: {
                                paddingRight: '14px',
                                whiteSpace: 'nowrap',
                              },
                            })}
                          {getFieldData(fields, 'email') &&
                            Email({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'email'),
                              tdProps: { align: 'left' },
                            })}
                        </tr>
                      )}
                      {(getFieldData(fields, 'companyPhone') ||
                        getFieldData(fields, 'companyEmail')) && (
                        <tr>
                          {getFieldData(fields, 'companyPhone') &&
                            CompanyPhone({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'companyPhone'),
                              style: {
                                paddingRight: '8px',
                              },
                            })}
                          {getFieldData(fields, 'companyEmail') &&
                            CompanyEmail({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'companyEmail'),
                              style: {},
                            })}
                        </tr>
                      )}
                      {(getFieldData(fields, 'companyAddress') ||
                        getFieldData(fields, 'website')) && (
                        <tr>
                          {getFieldData(fields, 'companyAddress') &&
                            CompanyAddress({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'companyAddress'),
                              style: {
                                paddingRight: '14px',
                              },
                            })}
                          {getFieldData(fields, 'website') &&
                            Website({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'website'),
                              tdProps: { align: 'left' },
                            })}
                        </tr>
                      )}

                      {getFieldData(fields, 'companyLogo') && (
                        <tr align="left">
                          {CompanyLogo({
                            data: getFieldData(fields, 'companyLogo'),
                            design,
                            style: {
                              paddingBottom: '6px',
                            },
                          })}
                        </tr>
                      )}
                    </table>
                  </td>
                </tr>
              )}
            </table>
          </td>
        </tr>

        {/* <tr>
      {getFieldData(fields, 'ctaUrl') &&
        getFieldData(fields, 'ctaTitle') &&
        CallToAction({
          signature,
          showAwsIcons,
          design,
          data: {
            ctaUrl: getFieldData(fields, 'ctaUrl'),
            ctaTitle: getFieldData(fields, 'ctaTitle'),
          },
          style: {
            paddingBottom: '8px',
          },
        })}
    </tr>
    <tr>
      {getFieldData(fields, 'ctaBannerImg') &&
        getFieldData(fields, 'ctaBannerUrl') &&
        CallToActionBanner({
          data: {
            ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
            ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
          },
          design,
          style: { textAlign: 'left', paddingBottom: '16px' },
        })}
    </tr>
    {!!getFieldData(fields, 'socialBanners')?.value?.length && (
      <tr>
        {SocialBanners({
          data: getFieldData(fields, 'socialBanners'),
          design,
          style: {
            textAlign: 'left',
          },
        })}
      </tr>
    )}
    {getFieldData(fields, 'footer') && (
      <tr>
        {Footer({
          data: getFieldData(fields, 'footer'),
          design,
          style: {
            textAlign: 'left',
          },
        })}
      </tr>
    )} */}
        {/* <tr>
      <Branding hideBranding={hideBranding} />
    </tr> */}
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleSix;
