/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS, TEXT_IDS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';
import AddOns from './components/AddOns';

function TemplateStyleTwenty({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section2 =
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'companyLogo');

  return (
    <>
      <table
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontFamily: font,
          userSelect: 'none',
        }}
      >
        {getFieldData(fields, 'signOff') && (
          <tr>
            {SignOff({
              signature,
              data: getFieldData(fields, 'signOff'),
              style: {
                fontFamily: 'inherit',
              },
            })}
          </tr>
        )}
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
              }}
            >
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    ref={mainContentRef}
                  >
                    <tr>
                      <td
                        style={{
                          paddingRight: '14px',
                        }}
                      >
                        {getFieldData(fields, 'headshotUrl') && (
                          <table cellPadding="0" cellSpacing="0">
                            <tr align="center">
                              {HeadshotImage({
                                data: getFieldData(fields, 'headshotUrl'),
                                design,
                              })}
                            </tr>
                          </table>
                        )}
                      </td>
                      <td>
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          style={{
                            paddingBottom: '6px',
                          }}
                        >
                          {!section2 && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    paddingBottom: '8px',
                                  }}
                                >
                                  {getFieldData(fields, 'name') && (
                                    <tr>
                                      {Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontWeight: 700,
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'position') && (
                                    <tr>
                                      {Position({
                                        signature,
                                        data: getFieldData(fields, 'position'),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'department') && (
                                    <tr>
                                      {Department({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'department',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'companyName') && (
                                    <tr>
                                      {CompanyName({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'companyName',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}

                          {getFieldData(fields, 'phone') &&
                            getFieldData(fields, 'email') && (
                              <tr>
                                <td>
                                  <table
                                    cellPadding="0"
                                    cellSpacing="0"
                                    border="0"
                                    style={{
                                      borderCollapse: 'collapse',
                                    }}
                                  >
                                    <tr>
                                      {getFieldData(fields, 'phone') &&
                                        Phone({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(fields, 'phone'),
                                          style: {
                                            paddingRight: '8px',
                                          },
                                        })}

                                      {getFieldData(fields, 'email') &&
                                        Email({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(fields, 'email'),
                                        })}
                                    </tr>
                                    {(getFieldData(fields, 'companyPhone') ||
                                      getFieldData(fields, 'companyEmail')) && (
                                      <tr>
                                        {getFieldData(fields, 'companyPhone') &&
                                          CompanyPhone({
                                            signature,
                                            showAwsIcons,
                                            design,
                                            data: getFieldData(
                                              fields,
                                              'companyPhone',
                                            ),
                                            style: {
                                              paddingRight: '8px',
                                            },
                                          })}
                                        {getFieldData(fields, 'companyEmail') &&
                                          CompanyEmail({
                                            signature,
                                            showAwsIcons,
                                            design,
                                            data: getFieldData(
                                              fields,
                                              'companyEmail',
                                            ),
                                            style: {},
                                          })}
                                      </tr>
                                    )}
                                  </table>
                                </td>
                              </tr>
                            )}

                          {getFieldData(fields, 'website') && (
                            <tr>
                              {Website({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'website'),
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyAddress') && (
                            <tr>
                              {CompanyAddress({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyAddress'),
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                    {!allUrlsAreNull && (
                      <tr>
                        <Divider signature={signature} design={design} />
                      </tr>
                    )}

                    {!allUrlsAreNull && (
                      <tr>
                        <td>
                          <table
                            cellPadding="0"
                            cellSpacing=""
                            border="0"
                            role="presentation"
                            style={{
                              fontSize: 'inherit',
                            }}
                          >
                            <tr>
                              {!!socialFields?.length &&
                                SocialLinks({
                                  signature,
                                  showAwsIcons,
                                  data: socialFields,
                                  design,
                                })}
                            </tr>
                          </table>
                        </td>
                        <td align="right">
                          <table
                            cellPadding="0"
                            cellSpacing=""
                            border="0"
                            role="presentation"
                            style={{
                              fontSize: 'inherit',
                            }}
                          >
                            <tr>
                              {getFieldData(fields, 'companyLogo') &&
                                CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                  toProps: {
                                    align: 'right',
                                    valign: 'center',
                                  },
                                })}
                            </tr>
                          </table>
                        </td>
                      </tr>
                    )}
                  </table>
                </td>
              </tr>
              {/* {getFieldData(fields, 'ctaUrl') &&
      getFieldData(fields, 'ctaTitle') && (
        <tr>
          {CallToAction({
            signature,
            showAwsIcons,
            design,
            data: {
              ctaUrl: getFieldData(fields, 'ctaUrl'),
              ctaTitle: getFieldData(fields, 'ctaTitle'),
            },
            style: {
              paddingTop: '0px',
            },
            tdProps: {
              style: { paddingBottom: '8px' },
            },
          })}
        </tr>
      )}
    <tr>
      {getFieldData(fields, 'ctaBannerImg') &&
        getFieldData(fields, 'ctaBannerUrl') &&
        CallToActionBanner({
          data: {
            ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
            ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
          },
          design,
          style: { textAlign: 'left' },
        })}
    </tr>
    {!!getFieldData(fields, 'socialBanners')?.value?.length && (
      <tr>
        {SocialBanners({
          data: getFieldData(fields, 'socialBanners'),
          design,
          style: {
            textAlign: 'left',
          },
        })}
      </tr>
    )}
    <tr>
      {getFieldData(fields, 'footer') &&
        Footer({
          data: getFieldData(fields, 'footer'),
          design,
        })}
    </tr> */}
            </table>
          </td>
        </tr>
        {/* <tr>
      <Branding hideBranding={hideBranding} />
    </tr> */}
      </table>
      <AddOns
        signature={signature}
        design={design}
        hideBranding={hideBranding}
        showAwsIcons={showAwsIcons}
      />
    </>
  );
}

export default TemplateStyleTwenty;
